import React from 'react';
import './App.css';
import Page from './Page.js';
import { Helmet } from 'react-helmet';


const TITLE = 'Client Matcher'

function App() {
  return (
    <>
    <Helmet>
        <title>{ TITLE }</title>
        <meta name="description" content="A Secure Client Matcher with Cobli" />
        <meta charSet="utf-8" />
    </Helmet>
    <Page />
    </>
  );
}

export default App;
