import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { sha256 } from 'js-sha256';
import Container from '@material-ui/core/Container';
import CopyToClipboard from "./CopyToClipboard";
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import hashedCobliClients from "./HashedClients.json";


class ConverterForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: '', hashes: '', open: false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this._getCNPJsInCommon = this._getCNPJsInCommon.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
  }

  _getCNPJsInCommon(currentClientsHashes, cnpjsClean) {
    const cnpjsInCommon = currentClientsHashes.map((hash, currentId) => {
      if (hashedCobliClients.indexOf(hash) >= 0) {
        return cnpjsClean[currentId];
      }

      return null;
    });

    return cnpjsInCommon.filter(x => x !== null);
  }

  handleCloseSnackbar(event) {
    this.setState({value: this.state.value, hashes: this.state.hashes, open: false})
  }

  handleChange(event) {
    this.setState({value: event.target.value, hashes: this.state.hashes, open: false});
  }

  handleSubmit(event) {
    event.preventDefault();

    const cnpjsDirty = this.state.value;
    const cnpjsClean = cnpjsDirty.split("\n").map(id => {
      return id.trim();
    });

    let hashes = cnpjsClean.map(cnpj => {
      const cnpjOnlyNumbers = cnpj.replace(/\.|\/|-/g, "");
      return sha256(cnpjOnlyNumbers);
    });

    let cnpjsInCommon = this._getCNPJsInCommon(hashes, cnpjsClean);

    if (cnpjsInCommon.length === 0) {
      // alert("");
      this.setState({value: this.state.value, hashes: "", open: true});
    } else {
      const reducer = (accumulator, currentValue) => accumulator + "\n" + currentValue;
      const cnpjsInCommonText = cnpjsInCommon.reduce(reducer);

      this.setState({value: this.state.value, hashes: cnpjsInCommonText, open: false});
    }
  }

  copyCodeToClipboard = () => {
    this.textArea.select();
    document.execCommand('copy');
  }

  render() {
    return (
      <Container>

          <TextField
            id="outlined-multiline-static"
            label="CNPJs"
            multiline
            rows="10"
            fullWidth
            variant="outlined"
            value={this.state.value}
            onChange={this.handleChange}
          />

          <Box m={2} />

          <Grid container justify="center">
            <Button variant="contained" onClick={this.handleSubmit} color="primary">
              Descubra!
            </Button>
          </Grid>

          <Box m={4} />

          <TextField
            id="outlined-multiline-static-2"
            ref={(textarea) => this.textArea = textarea}
            label="CNPJs em comum"
            multiline
            rows="10"
            disabled
            fullWidth
            variant="filled"
            value={this.state.hashes}
          />

          <Box m={2} />

          <Grid container justify="center">
            <CopyToClipboard>
              {({ copy }) => (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => copy(this.state.hashes)}
                >
                  Copiar!
                </Button>
              )}
            </CopyToClipboard>
          </Grid>

          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={this.handleCloseSnackbar}
            message="Não foram encontrados clientes em comum... :("
          />

      </Container>
    );
  }
}

export default ConverterForm;
